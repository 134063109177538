export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  ACCOUNT_CREATION_CONFIRMATION:
    "/account-creation-and-connection-confirmation",
  FORGOT_PASSWORD: "/forgotpassword",
  CONTROL_PANEL: {
    ROOT: "/control-panel",
    TAB: (tab: string, subTab?: string) => {
      let url = `/control-panel?tab=${tab}`;
      if (subTab) url += `&subTab=${subTab}`;
      return url;
    },
  },
  PROFILES: {
    ROOT: "/profiles",
    DETAIL: (slug = ":slug") => `/profiles/${slug}`,
  },
  TRAINERS_SEARCH: "/trainers-search",
  MY: {
    CLIENTS: "/my-clients",
    PROGRESS: "/my-progress",
    PRODUCTS: "/my-products",
    EVENTS: "/my-events",
    ARTICLES: "/my-articles",
  },
  PROGRESS: {
    PROFILE: (slug = ":slug") => `/progress/${slug}`,
  },
  MESSAGES: {
    ROOT: "/messages",
    MESSAGES_WITH_USER: (slug = ":slug") => `/messages?user=${slug}`,
  },
  VIDEO_MEETING: "/video-meeting",
  ARTICLES: {
    ROOT: "/articles",
    DETAIL: (slug = ":slug") => `/articles/${slug}`,
    ARTICLES_BY_AUTHOR: (author = ":author") => `/articles?author=${author}`,
  },
  ADD_NEW_ARTICLE: "/add-new-article",
  COMPANY: {
    PROFILE: (slug = ":slug") => `/company/profile/${slug}`,
    PROFILE_PACKAGES: (slug = ":slug") => `/company/profile/${slug}#packages`,
  },
  ADD_NEW_COMPANY: "/add-new-company",
  CLIENT_META: (slug = ":slug") => `/clientmeta/${slug}`,
  CALENDAR: {
    ROOT: "/calendar",
    DETAIL: (slug = ":slug") => `/calendar/${slug}`,
    CALENDAR_WITH_CREDIT: (slug = ":trainer", creditId = ":credit") =>
      `/calendar/${slug}?credit=${creditId}`,
    EDIT_RECURRING_DAYS_OFF: "/calendar/edit-recurring-days-off",
    EDIT_RECURRING_BREAKS: "/calendar/edit-recurring-breaks",
    OPTIONS: "/calendar/options",
  },
  EXERCISES: {
    ROOT: "/exercises",
    DETAIL: (slug = ":slug") => `/exercises/${slug}`,
    EXERCISES_BY_MUSCLE: (muscle = ":muscle") => `/exercises?muslces=${muscle}`,
  },
  EXERCISE_PROGRAMS: {
    USER: (slug = ":slug") => `/exercise-programs/${slug}`,
    EXERCISE_PROGRAM_VIEW_ROOT: "/exercise-program-view",
    EXERCISE_PROGRAM_VIEW: (id = ":id") => `/exercise-program-view/${id}`,
    EXERCISE_PROGRAM_EDIT: (id = ":id") => `/exercise-program-edit/${id}`,
  },
  USER_PROGRAMS: "/user/programs",
  EXERCISE_PROGRAM_CREATE: "/exercise-program-create",
  ANTERIOR_MUSCLES: {
    ROOT: "/anterior-muscles",
    DETAIL: (slug = ":slug") => `/anterior-muscles/${slug}`,
  },
  POSTERIOR_MUSCLES: {
    ROOT: "/posterior-muscles",
    DETAIL: (slug = ":slug") => `/posterior-muscles/${slug}`,
  },
  EVENTS: {
    ROOT: "/events",
    DETAIL: (id = ":id") => `/events/${id}`,
    METRICS: (id = ":id") => `/events/${id}/metrics`,
  },
  SHOP: {
    ROOT: "/shop",
    PRODUCT_DETAIL: (category = ":category", product = ":product") =>
      `/shop/${category}/${product}`,
    CATEGORY: (slug = ":slug") => `/shop/${slug}`,
  },
  PRODUCT_DETAILS: {
    DETAIL: (id = ":id") => `/product-details/${id}`,
  },
  ADD_NEW_PRODUCT: "/add-new-product",
  EDIT_PRODUCT: (slug = ":slug") => `/edit-product/${slug}`,
  BOOK_HOW_TO_WRITE_EXERCISE_PROGRAM: "https://amzn.to/2Vu0joW",
  CART: "/cart",
  SUCCESSFUL_PAYMENT: "/successful-payment",
  SUCCESSFUL_PAYMENT_URL: {
    LOCAL: "http://localhost:3000/successful-payment",
    PROD: "https://urbacise.com/successful-payment",
  },
  INDIVIDUAL_PURCHASE: (id = ":id") => `/individual_purchase/${id}`,
  PURCHASE_HISTORY: {
    ROOT: "/purchase-history",
    PRODUCT_DETAILS: (id = ":id") => `/purchase-history/product-details/${id}`,
  },
  FORMS: {
    ROOT: "/forms/",
    CREATE: "/forms/create",
    FILL: "/forms/fill",
  },
  BODY_FAT_CALCULATOR: "/body-fat-percentage-calculator",
  CONTACT: "/contact",
  FEEDBACK: "/feedback",
  TERMS: "/terms-and-conditions",
  COOKIE_POLICY: "/cookie-policy",
  ADMIN: {
    ROOT: "/admin",
    TRAINER_REVIEW: "/admin/trainer-review-admin",
    FEEDBACK: "/admin/feedback-admin",
    USERS: "/admin/users",
  },
};
